import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import { Dialog, Datepicker, ActionDialog } from "shared/components";
import { RATE_TYPES, VEHICLE_TYPES } from "../../shared/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { fieldErrorMessageMap, noop, VALIDATIONS } from "shared/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "./style";
import {
  preventInputKeyCodes,
  validator,
  getDateString,
  getUTCDateString,
} from "utils";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

let shouldShowAlert = {
  pay_rate: false,
  pay_quantity: false,
};

let isFieldChange = false;
const NUMBER_TYPE_FIELDS = [
  "bill_rate",
  "bill_quantity",
  "pay_rate",
  "pay_quantity",
  "pay_toll_amount",
  "pay_extra_miles",
  "reimbursement",
];

const defaultState = {
  customer: null,
  individual_contractor: null,
  location: null,
  route: null,
  vehicle_type: null,
  pay_rate_type: RATE_TYPES[0],
  pay_rate: "0",
  pay_quantity: 1,
  pay_extra_miles: 0,
  reimbursement: 0,
  pay_toll_amount: 0,
  kam_notes: null,
  review_notes: null,
  locationList: [],
  isLoading: false,
  dont_bill_notes: "",
  confirmDontBillValue: false,
  viewAllIC: false,
  defaultICList: [],
  confirmDontBill: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  dont_bill_tolls_notes: "",
  dont_pay_tolls_notes: "",
  dont_bill_miles_notes: "",
  dont_pay_miles_notes: "",
  dont_pay_notes: "",
  confirmDontPayValue: false,
  isPayRateDisabled: false,
  confirmDontPay: false,
  fromScheduleDate: null,
  toScheduleDate: null,
  pay_fuel_surcharge: 0,
  errors: {
    customer: " ",
    dont_bill_tolls_notes: " ",
    reimbursement: " ",
    dont_pay_tolls_notes: " ",
    dont_bill_miles_notes: " ",
    dont_pay_miles_notes: " ",
    location: " ",
    kam_notes: " ",
    dont_bill_notes: " ",
    review_notes: " ",
    route: " ",
    viewExistingNotes: false,
    showNotesValue: " ",
    pay_rate: " ",
    pay_quantity: " ",
    pay_extra_miles: " ",
    pay_toll_amount: " ",
    vehicle_type: " ",
    pay_rate_type: " ",
    fromScheduleDate: " ",
    toScheduleDate: " ",
  },
};

const Form = ({
  open = false,
  entry = null,
  isRoleKAM = false,
  priceMatrix = [],
  kam = {},
  tabIndex = 1,
  schedule_date = null,
  kamId = null,
  selectedKAMId = null,
  selectOriginalKAM = null,
  end_date = null,
  isNew = false,
  customerList = [],
  vehicleTypeList = [],
  contractorList = [],
  isMultipleRecord = false,
  isPriceMatrixFetched = false,
  formAlertFields = {},
  onClose = noop,
  handleUpdateEntries = noop,
  showFieldAlert = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: isCheckBoxChecked,
      confirmDontBillValue: isCheckBoxChecked,
      dont_bill_notes: "",
    }));
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: isCheckBoxChecked,
      confirmDontPayValue: isCheckBoxChecked,
      dont_pay_notes: "",
    }));
  };

  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
      dont_bill_tolls_notes: defaultState.dont_bill_tolls_notes,
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
      dont_pay_tolls_notes: defaultState.dont_pay_tolls_notes,
    }));
  };
  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
      dont_bill_miles_notes: defaultState.dont_bill_miles_notes,
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
      dont_pay_miles_notes: defaultState.dont_pay_miles_notes,
    }));
  };

  useEffect(() => {
    if (entry && !isNew) {
      const contractorDetails =
        contractorList.find(
          (item) => item.id === entry.individual_contractor?.id
        ) || {};

      const defaultICList = contractorList.filter(
        (item) => item.default_kam_id === selectedKAMId
      );

      const { contractorTypeValue, stand_by_rate_type, stand_by_rate } =
        contractorDetails;
      let finalNotes;

      if (entry.review_notes !== null && !!entry.review_notes.length) {
        const splitNotes = entry.review_notes?.split("||");

        finalNotes = splitNotes?.map((note) => {
          let str = note;

          const startIndexOfDate = str?.indexOf("-");
          const lastIndexOfDate = str?.indexOf(": ");
          const formattedDate = new Date(
              str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
            )?.toLocaleString(),
            str1 =
              str.substring(0, startIndexOfDate) +
              "-" +
              formattedDate +
              str.substring(lastIndexOfDate);
          return str1;
        });
      }

      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        customer: entry.customer_branch?.customer || defaultState.customer,
        individual_contractor: entry.individual_contractor
          ? {
              ...entry.individual_contractor,
              contractorTypeValue,
              stand_by_rate_type,
              stand_by_rate,
            }
          : defaultState.individual_contractor,
        location: entry.customer_branch || defaultState.location,
        route: entry?.routeDetails
          ? {
              name: entry?.routeDetails?.name,
              id: entry?.routeDetails?.id,
              price_type: entry?.routeDetails?.price_type,
              is_weekday: entry?.routeDetails?.is_weekday,
              matrix_name: entry?.routeDetails?.matrix_name,
            }
          : defaultState.route,
        vehicle_type: entry.vehicleType || defaultState.vehicle_type,

        pay_rate_type: entry.pay_rate_type || defaultState.pay_rate_type,
        pay_rate: entry.pay_rate || defaultState.pay_rate,
        pay_quantity: entry.pay_quantity || defaultState.pay_quantity,
        pay_fuel_surcharge:
          entry.pay_fuel_surcharge || defaultState.pay_fuel_surcharge,
        pay_extra_miles: entry.pay_extra_miles || defaultState.pay_extra_miles,
        pay_toll_amount: entry.pay_toll_amount || defaultState.pay_toll_amount,
        reimbursement: entry.reimbursement || defaultState.reimbursement,
        kam_notes: entry.kam_notes || defaultState.kam_notes,
        review_notes: finalNotes || defaultState.review_notes,
        state: entry.state || defaultState.state,
        confirmDontBillValue:
          entry.dont_bill || defaultState.confirmDontBillValue,
        confirmDontPayValue: entry.dont_pay || defaultState.confirmDontPayValue,
        confirmDontBillTollValue:
          entry.dont_bill_tolls || defaultState.confirmDontBillTollValue,
        confirmDontPayTollValue:
          entry.dont_pay_tolls || defaultState.confirmDontPayTollValue,
        confirmDontBillExtraMilesValue:
          entry.dont_bill_miles || defaultState.confirmDontBillExtraMilesValue,
        confirmDontPayExtraMilesValue:
          entry.dont_pay_miles || defaultState.confirmDontPayExtraMilesValue,
        dont_bill_notes: entry.dont_bill_notes || defaultState.dont_bill_notes,
        dont_pay_notes: entry.dont_pay_notes || defaultState.dont_pay_notes,
        dont_bill_tolls_notes:
          entry.dont_bill_tolls_notes || defaultState.dont_bill_tolls_notes,
        dont_pay_tolls_notes:
          entry.dont_pay_tolls_notes || defaultState.dont_pay_tolls_notes,
        dont_bill_miles_notes:
          entry.dont_bill_miles_notes || defaultState.dont_bill_miles_notes,
        dont_pay_miles_notes:
          entry.dont_pay_miles_notes || defaultState.dont_pay_miles_notes,
        viewAllIC:
          !defaultICList.some(
            (item) => item?.id === entry.individual_contractor?.id
          ) || defaultState.viewAllIC,
      }));
    }
  }, [entry]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      fromScheduleDate:
        isMultipleRecord && !isNew
          ? entry.schedule_date
          : schedule_date || defaultState.fromScheduleDate,
      toScheduleDate: end_date || defaultState.toScheduleDate,
    }));
  }, [schedule_date, end_date, entry]);

  useEffect(() => {
    if (!!Object.keys(formAlertFields).length) {
      setState((prevState) => ({
        ...prevState,
        ...formAlertFields,
        errors: {
          ...prevState.errors,
          ...formAlertFields.errors,
        },
      }));
    }
  }, [formAlertFields]);

  useEffect(() => {
    if (state.pay_rate_type === "Daily" && +state.pay_quantity > 1.99) {
      const errorMessage = validate("pay_quantity", +state.pay_quantity);
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            pay_quantity: errorMessage,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pay_rate_type, state.pay_quantity]);

  useEffect(() => {
    let filteredList = contractorList
      .filter((item) => item.default_kam_id !== null)
      .filter((item) => item.default_kam_id === selectedKAMId);

    if (state.viewAllIC) {
      const restOfList = contractorList.filter(
        (item) => item.default_kam_id !== selectedKAMId
      );
      filteredList = filteredList.concat(restOfList);
    }

    setState((prevState) => ({
      ...prevState,
      defaultICList: filteredList,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewAllIC, selectedKAMId]);

  const showExistingNotesValue = () => {
    const splitNotes = entry.kam_notes?.split("||");

    const finalNotes = splitNotes?.map((note) => {
      let str = note;

      const startIndexOfDate = str?.indexOf("-");
      const lastIndexOfDate = str?.indexOf(": ");
      const formattedDate = new Date(
          str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
        )?.toLocaleString(),
        str1 =
          str.substring(0, startIndexOfDate) +
          "-" +
          formattedDate +
          str.substring(lastIndexOfDate);
      return str1;
    });

    return setState((prevState) => ({
      ...prevState,
      showNotesValue: (finalNotes || []).join("\n"),
    }));
  };

  useEffect(() => {
    if (!isNew && !!entry.kam_notes) {
      showExistingNotesValue();
    }
  }, [state.showExistingNotesValue]);

  const getFieldValidatorMap = (field, state) => {
    const { pay_rate_type } = state;
    const fieldValidatorMap = {
      customer: [{ type: VALIDATIONS.REQUIRED, value: true }],
      // individual_contractor: [{ type: VALIDATIONS.REQUIRED, value: true }],
      location: [{ type: VALIDATIONS.REQUIRED, value: true }],
      kam_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      review_notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
      route: [{ type: VALIDATIONS.MAX_LENGTH, value: 30 }],

      pay_rate: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      dont_bill_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      dont_pay_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      dont_bill_tolls_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      dont_pay_tolls_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      dont_bill_miles_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      dont_pay_miles_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      pay_quantity: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      pay_toll_amount: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      reimbursement: [{ type: VALIDATIONS.MIN, value: 0 }],
      vehicle_type: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],

      pay_rate_type: [{ type: VALIDATIONS.REQUIRED, value: true }],
      pay_extra_miles: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
    };

    if (pay_rate_type === "Hourly") {
      fieldValidatorMap["pay_quantity"] = [
        {
          type: VALIDATIONS.MAX,
          value: 24,
        },
        ...(fieldValidatorMap["pay_quantity"] || []),
      ];
    }
    if (pay_rate_type === "Daily") {
      fieldValidatorMap["pay_quantity"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["pay_quantity"] || []),
      ];
    }

    // if (bill_rate_type === "Hourly") {
    //   fieldValidatorMap["bill_quantity"] = [
    //     {
    //       type: VALIDATIONS.MAX,
    //       value: 24,
    //     },
    //     ...(fieldValidatorMap["bill_quantity"] || []),
    //   ];
    // }

    if (isMultipleRecord && isNew) {
      fieldValidatorMap["fromScheduleDate"] = [
        {
          type: VALIDATIONS.REQUIRED,
          value: true,
        },
      ];
      fieldValidatorMap["toScheduleDate"] = [
        {
          type: VALIDATIONS.REQUIRED,
          value: true,
        },
      ];
    }
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(field, state);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;

    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";
    shouldShowAlert[field] = true;

    if (
      (type === "number" && value.indexOf(".") !== -1) ||
      field === "pay_toll_amount"
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
        // value = value + "";
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    if (
      state.route?.price_type === "Matrix" &&
      !settlementOptions?.includes(+state.pay_rate) &&
      !state.confirmDontPayValue
    ) {
      return toast.error(
        "Settlement value doesn't exist in price matrix for this customer."
      );
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const {
      location,
      individual_contractor,
      customer,
      locationList,
      route,
      isLoading,
      errors,
      vehicle_type,
      review_notes,
      defaultICList,
      ...payload
    } = state;

    Object.keys(payload).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        payload[key] = Number.isInteger(+payload[key])
          ? +payload[key]
          : (+payload[key]).toFixed(2);
      }
    });

    const serviceMethod = isNew ? "post" : "put";

    if (!isNew) {
      payload.id = entry.id;
    }
    if (tabIndex === 2 && isRoleKAM) {
      if (isNew) {
        payload.transfer_by = kamId;
        payload.transfer_to = kamId;
      } else {
        payload.transfer_to = kamId;
      }
    }
    if (!(isMultipleRecord && isNew)) {
      if (isNew && tabIndex === 2 && !isMultipleRecord) {
        payload.toScheduleDate = payload.fromScheduleDate;
      } else {
        payload.schedule_date = payload.fromScheduleDate;
        delete payload.fromScheduleDate;
        delete payload.toScheduleDate;
      }
    }

    const { data, error } = await Service[serviceMethod]({
      ...payload,
      vehicle_type: vehicle_type?.id || null,
      route_id: route?.id || null,
      customer_branch_id: location?.id,
      individual_contractor_id: individual_contractor?.id || null,
      kam_id:
        tabIndex === 2 && isRoleKAM ? selectOriginalKAM?.id : kamId || kam?.id,
      isMultipleRecord:
        isNew && tabIndex === 2 && !isMultipleRecord
          ? true
          : isMultipleRecord && isNew,
      dont_bill: state.confirmDontBillValue,
      dont_pay: state.confirmDontPayValue,
      dont_bill_tolls: state.confirmDontBillTollValue,
      dont_pay_tolls: state.confirmDontPayTollValue,
      dont_bill_miles: state.confirmDontBillExtraMilesValue,
      dont_pay_miles: state.confirmDontPayExtraMilesValue,
      review_notes: !isNew ? entry.review_notes : "",
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? (
          <>
            {error.map((item) => (
              <>
                {item.message}
                <ul>
                  {(item.submittedDates || []).map((date) => (
                    <li>
                      <b>{getUTCDateString(date)}</b>
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </>
        ) : (
          error.message
        )
      );
    }

    toast.success(`Entry ${isNew ? "created" : "updated"} successfully.`);
    if (!isNew) {
      handleUpdateEntries(data.updatedDSRecords);
    }
    setState((prevState) => ({ ...prevState, isLoading: false }));
    handleClose(true);
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    setState(defaultState);
    onClose(isSubmitted, state.customer);
  };

  const billPayPrice = priceMatrix?.filter((item) => {
    return (
      item.customer_branch_id === state?.location?.id &&
      item.name === state.route?.matrix_name
    );
  });

  const settlementOptions = billPayPrice?.map((item) => item.pay_price);

  const handlePayRateChange = (event, value) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      pay_rate: state.confirmDontPayValue ? "0.00" : value,
    }));
  };

  const handleFieldBlur = (evt) => {
    if (!isNew) {
      const field = evt.currentTarget?.name || evt.target?.name;
      const value = evt.currentTarget?.value || evt.target?.value;
      showFieldAlert(
        field,
        {
          ...state,
          [field]: value,
        },
        state.errors
      );
    }
  };

  const branchList = useMemo(
    () =>
      (
        customerList.find((customer) => customer.id === state.customer?.id)
          ?.customer_branches || []
      )
        .filter((branch) => branch.is_active && !branch.is_deleted)
        .sort((a, b) => {
          const valueA = (a.location || "").toLowerCase();
          const valueB = (b.location || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }),
    [state.customer]
  );
  const routeList = useMemo(() => {
    return (
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || []
    );
  }, [state.location]);

  const selectedRoute = customerList
    .filter((customer) => customer.id === state.customer?.id)[0]
    ?.customer_branches.map((item) => item.routes)
    .flat()
    .filter((route) => route.id === state.route?.id);

  const kamInfo = (kam) => {
    if (kam) {
      if (kam.first_name) {
        return `${kam.first_name} ${kam.last_name || ""} - ${kam.email} -(${
          kam.username
        })`;
      }
    }
    return "";
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>
          {`${isNew ? "New" : "Update"} Entry`}
        </Dialog.Title>
        <Dialog.Content>
          {!isRoleKAM && (
            <TextField
              fullWidth
              disabled
              label="KAM"
              variant="outlined"
              size="small"
              className="mb-4"
              value={tabIndex === 1 ? kamInfo(kam) : kamInfo(entry)}
            />
          )}
          {isRoleKAM && tabIndex === 2 && !!selectOriginalKAM && (
            <>
              <TextField
                fullWidth
                disabled
                label="KAM"
                variant="outlined"
                size="small"
                className="mb-4"
                value={`${selectOriginalKAM.first_name || ""} ${
                  selectOriginalKAM.last_name || ""
                }-${selectOriginalKAM.email || ""}-(${
                  selectOriginalKAM.username || ""
                })`}
              />
            </>
          )}
          {isMultipleRecord && (
            <div
              className={clsx(
                "d-flex f-align-center mb-4",
                classes.scheduleDateWrapper
              )}
            >
              <Datepicker
                mask
                preventClear
                disabled={!isNew}
                minDate={new Date(schedule_date)}
                maxDate={new Date(state.toScheduleDate)}
                selected={
                  isNew
                    ? state.fromScheduleDate
                    : getUTCDateString(state.fromScheduleDate)
                }
                label={isNew ? "From date *" : "Schedule date"}
                classes={{
                  input: {
                    root: clsx(classes.datepickerWrapper, { "pr-2": isNew }),
                  },
                }}
                onChange={(fromScheduleDate) => {
                  setState((prevState) => ({
                    ...prevState,
                    fromScheduleDate: getDateString(fromScheduleDate),
                  }));
                }}
              />
              {isNew && (
                <Datepicker
                  mask
                  preventClear
                  minDate={new Date(state.fromScheduleDate)}
                  maxDate={new Date(end_date)}
                  selected={state.toScheduleDate}
                  label="To date *"
                  classes={{
                    input: {
                      root: clsx("ml-2", classes.datepickerWrapper),
                    },
                  }}
                  onChange={(toScheduleDate) => {
                    setState((prevState) => ({
                      ...prevState,
                      toScheduleDate: getDateString(toScheduleDate),
                    }));
                  }}
                />
              )}
            </div>
          )}
          <div className="d-flex f-align-center">
            <Autocomplete
              disableClearable
              className="mr-2 w-50 mb-1"
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              size="small"
              value={state.customer}
              options={customerList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Customer"
                  variant="outlined"
                  error={!!state.errors.customer.trim()}
                  helperText={state.errors.customer}
                />
              )}
              onChange={(evt, value) => {
                const isStandBy =
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by";
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  customer: value,
                  location: null,
                  state: null,
                  route: null,
                  vehicle_type: defaultState.vehicle_type,
                  pay_rate_type: !isStandBy
                    ? defaultState.pay_rate_type
                    : prevState.pay_rate_type,
                  pay_quantity: defaultState.pay_quantity,
                  pay_rate: !isStandBy
                    ? defaultState.pay_rate
                    : prevState.pay_rate,
                }));
              }}
            />
            {/* <Tooltip title="Do not bill, if this is checked" placement="top">
              <Checkbox
                className="mb-6"
                checked={state.confirmDontBillValue}
                onChange={(evt) => handleConfirmDontBill(evt.target.checked)}
                name="Bill"
              />
            </Tooltip> */}

            <Autocomplete
              disableClearable
              className="w-50 ml-2 mb-1"
              size="small"
              value={state.location}
              options={branchList}
              getOptionLabel={(option) =>
                `${option.location} (${option.state?.name || ""} ,${
                  option?.pdxCompany?.value || ""
                })`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Location"
                  variant="outlined"
                  error={!!state.errors.location.trim()}
                  helperText={state.errors.location}
                />
              )}
              onChange={(evt, value) => {
                const isStandBy =
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by";
                isFieldChange = true;

                setState((prevState) => ({
                  ...prevState,
                  location: value,
                  state: value?.state,
                  route: null,
                  vehicle_type: defaultState.vehicle_type,
                  pay_rate_type: !isStandBy
                    ? defaultState.pay_rate_type
                    : prevState.pay_rate_type,
                  pay_quantity: defaultState.pay_quantity,
                  pay_rate: !isStandBy
                    ? defaultState.pay_rate
                    : prevState.pay_rate,
                }));
              }}
            />
          </div>

          <div className="d-flex f-align-center">
            <TextField
              className="mr-2 w-50 mb-1"
              name="state"
              label="State"
              variant="outlined"
              size="small"
              disabled={true}
              value={state.location?.state?.name || ""}
              helperText=" "
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="ml-2 w-50 mb-1"
              name="pdxCompany"
              disabled={true}
              label="PDX co"
              variant="outlined"
              size="small"
              value={state.location?.pdxCompany?.value || ""}
              helperText=" "
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <Autocomplete
              className="m2-2 w-50 mb-1"
              size="small"
              value={state.route}
              options={routeList}
              getOptionLabel={(option) =>
                `${option.name} (${
                  option.is_weekday === true ? "Weekday" : "Weekend"
                }, ${
                  option.price_type === null || option.price_type === "Standard"
                    ? "Standard"
                    : "Price Matrix"
                } )`
              }
              renderInput={(params) => (
                <TextField {...params} label="Route" variant="outlined" />
              )}
              onChange={(evt, value) => {
                const isStandBy =
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by";

                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  route: value,
                  pay_rate_type:
                    isStandBy &&
                    prevState?.route?.price_type === "Matrix" &&
                    (value?.price_type === null ||
                      value?.price_type === "Standard")
                      ? state.individual_contractor?.stand_by_rate_type
                      : value?.price_type === "Matrix"
                      ? RATE_TYPES[0]
                      : isStandBy
                      ? prevState.pay_rate_type
                      : value?.default_pay_rate_type ||
                        defaultState.pay_rate_type,
                  pay_rate: state.confirmDontPayValue
                    ? defaultState.pay_rate
                    : isStandBy &&
                      prevState?.route?.price_type === "Matrix" &&
                      (value?.price_type === null ||
                        value?.price_type === "Standard")
                    ? state.individual_contractor?.stand_by_rate
                    : value?.price_type === "Matrix"
                    ? value?.default_pay_rate || ""
                    : isStandBy
                    ? prevState.pay_rate
                    : value?.default_pay_rate || defaultState.pay_rate,
                  vehicle_type: value?.vehicleType || defaultState.vehicle_type,
                  pay_quantity:
                    value?.price_type === "Matrix"
                      ? "1"
                      : value?.default_pay_quantity ||
                        defaultState.pay_quantity,

                  pay_toll_amount:
                    state.confirmDontPayValue || state.confirmDontPayTollValue
                      ? defaultState.pay_toll_amount
                      : value?.pay_toll_amount || defaultState.pay_toll_amount,
                }));
              }}
            />
            <Autocomplete
              className="ml-2 mb-1 w-50"
              size="small"
              value={state.vehicle_type}
              options={vehicleTypeList}
              getOptionLabel={(option) => option?.value}
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle"
                  variant="outlined"
                  name="vehicle_type"
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  vehicle_type: value,
                  errors: {
                    ...prevState.errors,
                    vehicle_type: " ",
                  },
                }));
              }}
            />
          </div>
          <FormControlLabel
            className=" mb-2 ml-1"
            labelPlacement="start"
            label="View all IC:"
            control={
              <Checkbox
                color="primary"
                checked={state.viewAllIC}
                onChange={(evt) => {
                  setState((prevState) => ({
                    ...prevState,
                    viewAllIC: evt.target.checked,
                    individual_contractor: defaultState.individual_contractor,
                  }));
                }}
              />
            }
          />
          <div className="d-flex f-align-center">
            <Autocomplete
              className="mr-2 w-50 mb-1"
              size="small"
              value={{
                label: `${state.individual_contractor?.last_name || ""} ${
                  state.individual_contractor?.first_name || ""
                }`.trim(),
              }}
              options={state.defaultICList.map((item) => ({
                ...item,
                label: `${item.icid || ""} - ${item.last_name || ""} ${
                  item.first_name || ""
                }${
                  vehicleTypeList.some(
                    (ele) => ele.id === item.default_vehicle_type
                  )
                    ? `-(${
                        vehicleTypeList.find(
                          (ele) => ele.id === item.default_vehicle_type
                        ).value
                      })`
                    : ""
                }`,
              }))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contractor"
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => {
                  const isStandBy =
                    (value?.contractorTypeValue || "").toLowerCase() ===
                    "stand-by";

                  return {
                    ...prevState,
                    individual_contractor: value,
                    pay_rate:
                      state.route?.price_type === "Matrix"
                        ? state.route?.default_pay_rate || entry?.pay_rate || ""
                        : isStandBy
                        ? value?.stand_by_rate
                        : !!state.route
                        ? selectedRoute[0]?.default_pay_rate || entry.pay_rate
                        : defaultState.pay_rate,
                    pay_rate_type:
                      state.route?.price_type === "Matrix"
                        ? RATE_TYPES[0]
                        : isStandBy
                        ? value?.stand_by_rate_type
                        : !!state.route
                        ? selectedRoute[0]?.default_pay_rate_type ||
                          entry?.pay_rate_type
                        : RATE_TYPES[0],
                  };
                });
              }}
            />
            <TextField
              disabled={true}
              label="ICID"
              variant="outlined"
              size="small"
              className="ml-2 w-50 mb-1"
              value={state.individual_contractor?.icid || ""}
              helperText=" "
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div
            className="d-flex f-align-center"
            style={{
              marginBottom: "22px",
            }}
          >
            <div className="w-50">
              <FormControlLabel
                value="top"
                // disabled={state.route?.price_type === "Matrix"}
                control={
                  <Checkbox
                    style={{
                      padding: "0px 9px",
                      marginBottom: "0px !important",
                    }}
                    checked={
                      state.confirmDontBillValue
                      // state.route?.price_type === "Matrix"
                      //   ? false
                      //   : state.confirmDontBillValue
                    }
                    onChange={(evt) =>
                      handleConfirmDontBill(evt.target.checked)
                    }
                    name="Bill"
                  />
                }
                className=" mb-2 ml-1"
                label="Do not bill, if checked:"
                labelPlacement="start"
              />
            </div>
            <div className="w-50">
              <FormControlLabel
                value="top"
                // disabled={state.route?.price_type === "Matrix"}
                control={
                  <Checkbox
                    style={{
                      padding: "0px 9px",
                      marginBottom: "0px !important",
                    }}
                    checked={
                      state.confirmDontPayValue
                      // state.route?.price_type === "Matrix"
                      //   ? false
                      //   : state.confirmDontPayValue
                    }
                    onChange={(evt) => handleConfirmDontPay(evt.target.checked)}
                    name="Pay"
                  />
                }
                className=" mb-2 ml-2"
                label="Do not pay, if checked:"
                labelPlacement="start"
              />
            </div>
          </div>

          <div className="d-flex f-align-center">
            <Autocomplete
              disabled={
                state.route?.price_type === "Matrix" ||
                (
                  state.individual_contractor?.contractorTypeValue || ""
                ).toLowerCase() === "stand-by"
              }
              disableClearable
              className="w-50 mr-2 mb-1"
              size="small"
              value={state.pay_rate_type}
              options={RATE_TYPES}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Pay Rate Type"
                  variant="outlined"
                  error={!!state.errors.pay_rate_type.trim()}
                  helperText={state.errors.pay_rate_type}
                />
              )}
              onChange={(evt, value) => {
                if (state.pay_rate_type === value) {
                  return false;
                } else {
                  isFieldChange = true;
                  !isNew &&
                    showFieldAlert("pay_rate_type", {
                      ...state,
                      pay_quantity:
                        value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
                      pay_rate_type: value,
                    });
                  setState((prevState) => ({
                    ...prevState,
                    pay_rate_type: value,
                    pay_quantity:
                      value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
                    errors: {
                      ...prevState.errors,
                      bill_quantity: " ",
                    },
                  }));
                }
              }}
            />
            <TextField
              className="ml-2 w-50 mb-1"
              type="number"
              name="pay_quantity"
              label="Pay Quantity"
              variant="outlined"
              size="small"
              value={state.pay_quantity}
              disabled={state.route?.price_type === "Matrix"}
              onWheel={(event) => event.target.blur()}
              error={!!state.errors.pay_quantity?.trim()}
              helperText={state.errors.pay_quantity}
              onChange={handleFieldChange}
              onBlur={(evt) => {
                const { name } = evt.target;
                if (shouldShowAlert[name]) {
                  shouldShowAlert[name] = false;
                  handleFieldBlur(evt);
                }
              }}
              onKeyDown={preventInputKeyCodes}
            />
          </div>
          {state.route?.price_type === "Matrix" && (
            <TextField
              className="w-50 mr-2 mb-1"
              disabled={true}
              fullWidth
              label="Price Matrix Name"
              variant="outlined"
              size="small"
              value={state.route?.matrix_name || ""}
              helperText=" "
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <div className="d-flex f-align-center mb-5">
            <div className="w-50">
              <FormControlLabel
                value="top"
                disabled={state.confirmDontBillValue}
                control={
                  <Checkbox
                    className="mb-1"
                    checked={state.confirmDontBillExtraMilesValue}
                    onChange={(evt) =>
                      handleConfirmDontBillExtraMiles(evt.target.checked)
                    }
                    name="do_not_bill_extra_miles"
                  />
                }
                className="ml-1 mb-2 mr-10"
                label="Do not bill extra miles, if checked:"
                labelPlacement="start"
              />
            </div>
            <div className="w-50">
              <FormControlLabel
                value="top"
                disabled={state.confirmDontPayValue}
                control={
                  <Checkbox
                    className="mb-1"
                    checked={state.confirmDontPayExtraMilesValue}
                    onChange={(evt) =>
                      handleConfirmDontPayExtraMiles(evt.target.checked)
                    }
                    name="do_not_pay_extra_miles"
                  />
                }
                className="mb-2 mr-10"
                label="Do not pay extra miles, if checked:"
                labelPlacement="start"
              />
            </div>
          </div>
          <div className="d-flex f-align-center">
            {state.route?.price_type === undefined ||
            state.route?.price_type === null ||
            state.route?.price_type === "Standard" ? (
              <TextField
                disabled={
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by" || state.confirmDontPayValue
                }
                className="mr-2 w-50 mb-1"
                type="number"
                name="pay_rate"
                label="Pay Rate"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                value={state.pay_rate}
                error={!!state.errors.pay_rate?.trim()}
                helperText={state.errors.pay_rate}
                onWheel={(event) => event.target.blur()}
                onChange={handleFieldChange}
                onBlur={(evt) => {
                  const { name } = evt.target;
                  if (shouldShowAlert[name]) {
                    shouldShowAlert[name] = false;
                    handleFieldBlur(evt);
                  }
                }}
                onKeyDown={preventInputKeyCodes}
              />
            ) : (
              <Autocomplete
                disableClearable
                size="small"
                className="w-50 mr-1 mb-8"
                value={state.pay_rate || ""}
                options={settlementOptions}
                disabled={state.confirmDontPayValue}
                getOptionLabel={(option) => {
                  const matchingBillPayPrice = billPayPrice?.find(
                    (billOption) => billOption?.pay_price == option
                  );
                  if (matchingBillPayPrice) {
                    return `$ ${option} (${matchingBillPayPrice.quantity})`;
                  } else {
                    return `$ ${option}`;
                  }
                }}
                renderInput={(params) =>
                  isPriceMatrixFetched ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      required
                      label="Pay Rate"
                      variant="outlined"
                    />
                  )
                }
                getOptionSelected={(option, value) => `${option}` === value}
                onChange={handlePayRateChange}
              />
            )}
            {!isNew && (
              <TextField
                className="mr-2 w-50 mb-1 ml-2"
                type="number"
                name="pay_fuel_surcharge"
                label="Pay Fuel Surcharge"
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment>%</InputAdornment>,
                }}
                helperText={" "}
                value={state.pay_fuel_surcharge}
              />
            )}

            <TextField
              className="ml-2 w-50 mb-1"
              type="number"
              name="pay_extra_miles"
              label="Pay Extra Miles"
              variant="outlined"
              size="small"
              disabled={
                state.confirmDontPayValue || state.confirmDontPayExtraMilesValue
              }
              value={state.pay_extra_miles}
              error={!!state.errors.pay_extra_miles?.trim()}
              helperText={state.errors.pay_extra_miles}
              onWheel={(event) => event.target.blur()}
              onChange={handleFieldChange}
              onKeyDown={preventInputKeyCodes}
            />
          </div>
          <div className="d-flex f-align-center mb-5">
            <div className="w-50">
              <FormControlLabel
                value="top"
                disabled={state.confirmDontBillValue}
                control={
                  <Checkbox
                    className="mb-1"
                    checked={state.confirmDontBillTollValue}
                    onChange={(evt) =>
                      handleConfirmDontBillToll(evt.target.checked)
                    }
                    name="do_not_bill_toll_amount"
                  />
                }
                className=" mb-2 ml-1"
                label="Do not bill toll, if checked:"
                labelPlacement="start"
              />
            </div>
            <div className="w-50">
              <FormControlLabel
                value="top"
                disabled={state.confirmDontPayValue}
                control={
                  <Checkbox
                    className="mb-1"
                    checked={state.confirmDontPayTollValue}
                    onChange={(evt) =>
                      handleConfirmDontPayToll(evt.target.checked)
                    }
                    name="do_not_pay_toll_amount"
                  />
                }
                className="mb-2 ml-2"
                label="Do not pay toll, if checked:"
                labelPlacement="start"
              />
            </div>
          </div>
          <div className="d-flex f-align-center">
            <TextField
              fullWidth
              className="mb-1"
              type="number"
              name="pay_toll_amount"
              label="Pay Toll Amount"
              variant="outlined"
              disabled={
                state.confirmDontPayValue || state.confirmDontPayTollValue
              }
              onWheel={(event) => event.target.blur()}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={state.pay_toll_amount}
              error={!!state.errors.pay_toll_amount?.trim()}
              helperText={state.errors.pay_toll_amount}
              onChange={handleFieldChange}
              onKeyDown={preventInputKeyCodes}
            />
            <TextField
              fullWidth
              className="ml-2 w-50 mb-1"
              type="number"
              name="reimbursement"
              label="Incentive"
              variant="outlined"
              onWheel={(event) => event.target.blur()}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={state.reimbursement}
              error={!!state.errors.reimbursement?.trim()}
              helperText={state.errors.reimbursement}
              onChange={handleFieldChange}
              onKeyDown={preventInputKeyCodes}
            />
          </div>
          <TextField
            fullWidth
            multiline
            disbaled={!isRoleKAM}
            name="kam_notes"
            rows={2}
            maxRows={6}
            label="KAM Notes"
            variant="outlined"
            size="small"
            value={state.kam_notes}
            error={!!state.errors.kam_notes?.trim()}
            helperText={state.errors.kam_notes}
            onChange={handleFieldChange}
          />
          {!state.review_notes ? (
            <TextField
              fullWidth
              className="mb-1"
              multiline
              name="review_notes"
              rows={2}
              disabled
              label="Review Notes"
              variant="outlined"
              size="small"
              value={
                !!state.review_notes
                  ? !!state.review_notes[1]
                    ? state.review_notes[0] + "..."
                    : state.review_notes[0]
                  : "-"
              }
              error={!!state.errors.review_notes?.trim()}
              helperText={state.errors.review_notes}
              onChange={handleFieldChange}
            />
          ) : (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: (state.review_notes || [])?.join("<br>"),
                  }}
                />
              }
              placement="top-start"
            >
              <TextField
                fullWidth
                className="mb-1"
                multiline
                name="review_notes"
                rows={2}
                disabled
                label="Review Notes"
                variant="outlined"
                size="small"
                value={
                  !!state.review_notes
                    ? !!state.review_notes[1]
                      ? state.review_notes[0] + "..."
                      : state.review_notes[0]
                    : "-"
                }
                error={!!state.errors.review_notes?.trim()}
                helperText={state.errors.review_notes}
                onChange={handleFieldChange}
              />
            </Tooltip>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button
              variant="outlined"
              onClick={handleClose}
              className="ml-2 mr-2"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isLoading ||
                (state.route?.price_type === "Matrix" && !state.pay_rate) ||
                !isFieldChange ||
                validate()
              }
              onClick={handleSubmit}
            >
              {`${isNew ? "Create" : "Update"}`}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.confirmDontBill && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBill}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, bill rate will become 0. This action will not be
                reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_bill_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_bill_notes?.trim()}
                helperText={state.errors.dont_bill_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
              dont_bill_tolls_notes: defaultState.dont_bill_tolls_notes,
              confirmDontBillTollValue: defaultState.confirmDontBillTollValue,
              confirmDontBillExtraMilesValue:
                defaultState.confirmDontBillExtraMilesValue,
              dont_bill_miles_notes: defaultState.dont_bill_miles_notes,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
              confirmDontBillValue: false,
              dont_bill_notes: "",
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            state.isLoading ||
            !!state.errors.dont_bill_notes?.trim() ||
            !state.dont_bill_notes?.trim()
          }
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPay && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPay}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, pay rate will become 0. This action will not be
                reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_pay_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_pay_notes?.trim()}
                helperText={state.errors.dont_pay_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
              pay_rate: defaultState.pay_rate,
              pay_toll_amount: defaultState.pay_toll_amount,
              pay_extra_miles: defaultState.pay_extra_miles,
              isPayRateDisabled: true,
              dont_pay_tolls_notes: defaultState.dont_pay_tolls_notes,
              confirmDontPayTollValue: defaultState.confirmDontPayTollValue,
              confirmDontPayExtraMilesValue:
                defaultState.confirmDontPayExtraMilesValue,
              dont_pay_miles_notes: defaultState.dont_pay_miles_notes,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
              confirmDontPayValue: false,
              dont_pay_notes: "",
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            state.isLoading ||
            !!state.errors.dont_pay_notes?.trim() ||
            !state.dont_pay_notes?.trim()
          }
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillToll}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, bill toll amount will become 0. This action will
                not be reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_bill_tolls_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_bill_tolls_notes?.trim()}
                helperText={state.errors.dont_bill_tolls_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
              confirmDontBillTollValue: false,
              dont_bill_tolls_notes: defaultState.dont_bill_tolls_notes,
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            !!state.errors.dont_bill_tolls_notes?.trim() ||
            !state.dont_bill_tolls_notes?.trim()
          }
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayToll}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, pay toll amount will become 0. This action will not
                be reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_pay_tolls_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_pay_tolls_notes?.trim()}
                helperText={state.errors.dont_pay_tolls_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
              pay_toll_amount: defaultState.pay_toll_amount,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
              confirmDontPayTollValue: false,
              dont_pay_tolls_notes: defaultState.dont_pay_tolls_notes,
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            !!state.errors.dont_pay_tolls_notes?.trim() ||
            !state.dont_pay_tolls_notes?.trim()
          }
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillExtraMiles}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, bill extra miles will become 0. This action will
                not be reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_bill_miles_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_bill_miles_notes?.trim()}
                helperText={state.errors.dont_bill_miles_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
              confirmDontBillExtraMilesValue: false,
              dont_bill_miles_notes: defaultState.dont_bill_miles_notes,
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            !!state.errors.dont_bill_miles_notes?.trim() ||
            !state.dont_bill_miles_notes?.trim()
          }
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayExtraMiles}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, pay extra miles will become 0. This action will not
                be reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_pay_miles_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_pay_miles_notes?.trim()}
                helperText={state.errors.dont_pay_miles_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
              pay_extra_miles: defaultState.pay_extra_miles,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
              confirmDontPayExtraMilesValue: false,
              dont_pay_miles_notes: defaultState.dont_pay_miles_notes,
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            !!state.errors.dont_pay_miles_notes?.trim() ||
            !state.dont_pay_miles_notes?.trim()
          }
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
    </>
  );
};

export default Form;
