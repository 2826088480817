import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  Fragment,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  getDateString,
  getTokenData,
  hasFeatureAccess,
  queryStringBuilderNew,
  getPageConfig,
  setPageConfig,
  updatePageConfig,
  useOnScreen,
} from "utils";
import { AppContext } from "shared/contexts";
import FlagIcon from "@material-ui/icons/Flag";
import { ActionDialog, Dropdown, FilterComponent } from "shared/components";
import { CollapsibleGrid } from "modules/shared/components";
import SharedService from "modules/shared/service";
import { PAGE_KEYS, isTransferScheduleEnable } from "shared/constants";
import TuneIcon from "@material-ui/icons/Tune";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import WarningIcon from "@material-ui/icons/Warning";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Chip from "@material-ui/core/Chip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import Service from "../service";
import Form from "../form";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DynamicForm } from "modules/shared/components";
import { noop } from "shared/constants";
import { PERMISSION, ROLE, RATE_TYPES } from "modules/shared/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { VALIDATIONS } from "shared/constants";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import useStyles from "./style";
import { CompassCalibrationOutlined } from "@material-ui/icons";
import IcForm from "modules/shared/components/ic-form";

let gridHelper = null,
  timeout = null;

const RECORD_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const defaultState = {
  entries: [],
  customerlocationList: [],
  isIcFetching: false,
  totalEntries: 0,
  priceMatrix: [],
  isPriceMatrixFetched: false,
  isFetching: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    users: false,
    IC: false,
  },
  isFiltering: false,
  icDetails: {},
  dimensions: 0,
  showIcDetails: false,
  pageFilters: [],
  filters: {
    kam: [],
    roasterType: [],
    locations: [],
  },
  selectedKAM: [],
  selectedLocation: [],
  selectedRows: [],
  stateList: [],
  cautionFlagSubmitReasonList: [],
  pdxCompanyList: [],
  locationList: [],
  customerChecked: false,
  ICChecked: false,
  search: "",
  searchColumns: [],
  isNew: false,
  deletingEntryIds: null,
  markActiveInactiveIds: [],
  rowBeingEdited: null,
  openForceSubmitDialog: false,
  isRowOpen: false,
  isFetchingLeaveTypeList: false,
  isFetchingCautionFlagTypeList: false,
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    roster_type: {
      label: "Roster Type",
      value: "roster_type",
      isSelected: true,
    },
    // dont_bill: {
    //   label: "Do Not Bill",
    //   value: "dont_bill",
    //   isSelected: true,
    // },
    kam: {
      label: "KAM",
      value: "kam",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },

    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },
    pay_rate: { label: "Pay Rate", value: "pay_rate", isSelected: true },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    notes: {
      label: "Notes",
      value: "notes",
      isSelected: true,
    },
  },
  entry: {
    status: [],
  },
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    errors: {},
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  value: "hello",
  vehicleTypeList: [],
};

const ViewBaseRoster = ({ match, history }) => {
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);
  const classes = useStyles();
  const actionRef = useRef(null);
  const userData = getTokenData();
  const location = useLocation();
  const data = location.state || {};
  const isActionBarOnScreen = useOnScreen(actionRef);
  const pageConfig = getPageConfig(PAGE_KEYS.BASE_ROSTER);
  const currentUser = getTokenData() || {};

  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleVIEW = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const tableHeadRef = useRef(null);

  const handleRowOpen = (value) =>
    setState((prevState) => ({
      ...prevState,
      isRowOpen: value || defaultState.isRowOpen,
    }));

  const handleScroll = (event) => {
    const targetDiv = event.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
      }
    );

    const IC = appData.IC.filter((ic) => ic.is_active);
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label: list?.name || `${list.first_name || ""} ${list.last_name || ""}`,
      }));
    };
    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      kamUsers: users.kam,
      customers,
      IC,
      icList,
      ICOptions,
    };
  }, [appData]);

  const kamOptions = appDataList.kamUsers?.sort(
    (a, b) => b.is_active - a.is_active
  );

  useEffect(() => {
    if (appDataList.kamUsers?.length && data?.kam_id) {
      const selectedKAM = appDataList.kamUsers?.filter(
        (kam) => kam?.id == data?.kam_id
      );
      setState((prevState) => ({
        ...prevState,
        selectedKAM,
      }));
      window.history.replaceState(null, document.title);
      history.replace({ ...history.location, state: null });
    }
  }, [appDataList.kamUsers]);

  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    selectedKAM:
      (pageConfig && pageConfig?.kamFilter?.value) || defaultState.selectedKAM,
    // selectedLocation:
    //   (pageConfig && pageConfig?.locationFilter?.value) ||
    //   defaultState.selectedLocation,
    customerChecked:
      (pageConfig && pageConfig?.customer) || defaultState.customerChecked,
    ICChecked: (pageConfig && pageConfig?.IC) || defaultState.ICChecked,
    entry: {
      status:
        pageConfig && pageConfig?.status
          ? [
              pageConfig?.status?.active === false ? [] : RECORD_STATUS.ACTIVE,
              pageConfig?.status?.inActive === false
                ? []
                : RECORD_STATUS.INACTIVE,
            ]
          : [RECORD_STATUS.ACTIVE, RECORD_STATUS.INACTIVE],
    },
    testId: data?.base_roster_id || null,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    searchColumns:
      (pageConfig && pageConfig?.searchColumns) || defaultState.searchColumns,
    filters: {
      ...defaultState.filters,
      kam: (pageConfig && pageConfig?.kamFilter?.kam) || [],
      // locations: (pageConfig && pageConfig?.locationFilter?.locations) || [],
      roasterType: (pageConfig && pageConfig?.roasterType) || [],
      // kam: data?.kam_id
      //   ? [
      //       {
      //         field: "kam_id",
      //         type: "=",
      //         value: data.kam_id,
      //       },
      //     ]
      //   : [],
    },
  });

  useEffect(() => {
    if (tableHeadRef.current) {
      setState((prevState) => ({
        ...prevState,
        dimensions:
          tableHeadRef?.current?.clientWidth +
          tableHeadRef?.current?.clientHeight,
      }));
    }
  }, [state.isRowOpen]);

  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const canViweMemberBaseRoster = hasFeatureAccess(
    PERMISSION.VIEW_MEMEBER_BASE_ROSTER
  );

  const getSearchArray = (searchColumns, search) => {
    const columns = searchColumns.length
      ? [...searchColumns]
      : [
          "icid",
          "contractor_first_name",
          "customer_name",
          "location",
          "route",
          "vehicle_type_value",
        ];
    // if (columns.includes("contractor_first_name")) {
    //   columns.push("contractor_last_name");
    // }
    return columns.map((column) => {
      // const searchArr = search.split(" ");
      // if (
      //   (column === "contractor_first_name" ||
      //     column === "contractor_last_name") &&
      //   searchArr.length > 1
      // ) {
      //   return {
      //     field: column,
      //     value:
      //       column === "contractor_first_name" ? searchArr[0] : searchArr[1],
      //   };
      // }

      return {
        field: column,
        value: search,
      };
    });
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const getPrizeMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isPriceMatrixFetched: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPriceMatrixFetched: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      priceMatrix: data.rows || defaultState.priceMatrix,
      isPriceMatrixFetched: false,
    }));
  }, []);

  useEffect(() => {
    getPrizeMatrix();
  }, []);

  const filterConfig = useMemo(
    () => [
      {
        field: "location",
        fieldToDisplay: "Location",
        operatorType: "string",
        options: Object.entries(state.customerlocationList)
          .map(([state, value]) =>
            value.map((item) => ({
              label: `${item}-(${state})`,
              value: item,
            }))
          )
          .flat()
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },

      {
        field: "route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle_type_value",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList.map((vehicle) => ({
          ...vehicle,
          label: vehicle.value,
          value: vehicle.value,
        })),
      },
      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "Daily" },
          { label: "Hourly", value: "Hourly" },
          { label: "Per Miles", value: "Per Miles" },
          { label: "Per Tire", value: "Per Tire" },
          { label: "Per Run", value: "Per Run" },
        ],
      },
      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "number",
      },
      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "number",
      },
      {
        field: "pay_toll_amount",
        fieldToDisplay: "Pay Toll Amount",
        operatorType: "number",
      },
    ],
    [
      state.vehicleTypeList,
      state.customerlocationList,
      state.stateList,
      state.pdxCompanyList,
      appDataList?.customers,
    ]
  );

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      searchColumns,
      pageFilters = [],
      hasLoader = true
    ) => {
      const searchArray = getSearchArray(searchColumns, search);

      let filtersArr = [];
      if (filters.kam.length && filters.kam[0].value) {
        filtersArr = [...filtersArr, ...filters.kam];
      }

      // if (filters.locations.length) {
      //   filtersArr = [...filtersArr, ...filters.locations];
      // }

      if (filters.roasterType.length) {
        filtersArr = [...filtersArr, ...filters.roasterType];
      }

      filtersArr = [
        ...filtersArr.map((item) => {
          if (item.field === "location") {
            return { ...item, notIncludeInBuilder: false };
          }
          return { ...item, notIncludeInBuilder: true };
        }),
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      const queryString = queryStringBuilderNew(
        0,
        0,
        searchArray,
        filtersArr,
        {},
        search && search.length > 0
      );

      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const { data, error } = await Service.get(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      const sortedEntries = (data.result || defaultState.entries).sort(
        (rowA, rowB) => {
          const rowAcustomerName = (
            rowA?.customer_branch?.customer?.name || ""
          ).toLowerCase();
          const rowAlocation = (
            rowA?.customer_branch?.location || ""
          ).toLowerCase();
          const rowAroute = (rowA?.route || "").toLowerCase();
          const rowBcustomerName = (
            rowB?.customer_branch?.customer?.name || ""
          ).toLowerCase();
          const rowBlocation = (
            rowB?.customer_branch?.location || ""
          ).toLowerCase();
          const rowBroute = (rowB?.route || "").toLowerCase();
          const valueA = `${rowAcustomerName}${rowAlocation}${rowAroute}`;
          const valueB = `${rowBcustomerName}${rowBlocation}${rowBroute}`;
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        }
      );
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: sortedEntries,
        totalEntries: data.count,
      }));
    },
    []
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.BASE_ROSTER, {
      filters: pageFilters,
    });
  }, []);

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.delete(entryIds);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      fetchEntries(
        state.search,
        state.filters,
        state.searchColumns,
        state.pageFilters
      );
    }
  };

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: true,
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingLeaveTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleFieldAlertSubmit = async (id, payload, errors) => {
    if (state.rowBeingEdited) {
      setState((prevState) => ({
        ...prevState,
        fieldAlert: defaultState.fieldAlert,
        formAlertFields: {
          ...prevState.formAlertFields,
          ...payload,
          errors: { ...errors },
        },
      }));
    }
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const showFieldAlert = (field, row, errors) => {
    const alertFieldGroup = [
      {
        group: "bill",
        fields: ["bill_rate_type", "bill_quantity", "bill_rate"],
      },
      { group: "pay", fields: ["pay_rate_type", "pay_quantity", "pay_rate"] },
    ];

    const group = (
      alertFieldGroup.find((item) => item.fields.includes(field)) || {}
    ).group;

    if (!group || !row) {
      return;
    }
    const validations = [
      { type: VALIDATIONS.MIN, value: 0 },
      { type: VALIDATIONS.LIMIT, value: 10.2 },
    ];

    setState((prevState) => ({
      ...prevState,
      fieldAlert: {
        ...prevState.fieldAlert,
        show: true,
        id: row?.id,
        errors: {
          ...errors,
        },
        onChange: (field, value, updatedState = {}, callback) => {
          if (field === "bill_rate_type") {
            updatedState = {
              ...updatedState,
              bill_quantity:
                value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
              errors: {
                ...updatedState.errors,
                bill_quantity: " ",
              },
            };
            callback(updatedState);
          }
          if (field === "pay_rate_type") {
            updatedState = {
              ...updatedState,
              pay_quantity:
                value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
              errors: {
                ...updatedState.errors,
                pay_quantity: " ",
              },
            };
            callback(updatedState);
          }
        },
        updateValidations: (fields = {}, fieldValidatorMap = {}) => {
          let updatedFieldValidatorMap = { ...fieldValidatorMap };

          if (fields["bill_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["bill_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["bill_quantity"] = [
                ...updatedFieldValidatorMap["bill_quantity"],
                {
                  type: VALIDATIONS.MAX,
                  value: 24,
                  id: "max_error",
                },
              ];
            }
          }

          if (fields["pay_rate_type"] === "Daily") {
            updatedFieldValidatorMap["pay_quantity"] = [
              { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
              ...updatedFieldValidatorMap["pay_quantity"],
            ];
          }

          if (fields["pay_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["pay_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["pay_quantity"] = [
                ...updatedFieldValidatorMap["pay_quantity"],
                {
                  type: VALIDATIONS.MAX,
                  value: 24,
                  id: "max_error",
                },
              ];
            }
          }

          return updatedFieldValidatorMap;
        },
        subHeading:
          group === "bill"
            ? `Bill Rate Type, Bill Quantity, and Bill Rate are interrelated fields.`
            : `Pay Rate Type, Pay Quantity, and Pay Rate are interrelated fields.`,
        configs: [
          {
            type: "autoComplete",
            name: `${group}_rate_type`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate Type`,
            value: row[`${group}_rate_type`],
            options: RATE_TYPES,
            disableClearable: true,
            required: true,
          },
          {
            type: "textField",
            name: `${group}_quantity`,
            label: `${group === "bill" ? "Bill" : "Pay"} Quantity`,
            value: row[`${group}_quantity`],
            inputType: "number",
            validations: validations,
          },
          {
            type: "textField",
            name: `${group}_rate`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate`,
            value: row[`${group}_rate`],
            inputType: "number",
            inputProps: {
              startAdornment: <InputAdornment>$</InputAdornment>,
            },
            validations: validations,
          },
        ],
      },
    }));
  };

  const moreActions = (row) => {
    return !isRoleVIEW ? (
      <div className="d-flex f-align-center f-justify-end">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className={clsx("ml-2 mr-2 c-pointer", {
              disabled: !row.kamUser.is_active,
            })}
            onClick={() => {
              getPrizeMatrix();
              setState((prevState) => ({ ...prevState, rowBeingEdited: row }));
            }}
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color={!row.kamUser.is_active ? "" : "error"}
            className={clsx("ml-2 mr-2 c-pointer", {
              disabled: !row.kamUser.is_active,
            })}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: row.id,
              }))
            }
          />
        </Tooltip>
        <Tooltip
          title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
          placement="top-start"
        >
          <Switch
            size="small"
            disabled={!row.kamUser.is_active}
            checked={row.is_active}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: [
                  { recordId: row.id, status: row.is_active },
                ],
              }));
            }}
            color="primary"
          />
        </Tooltip>
      </div>
    ) : (
      <>
        {" "}
        <Tooltip
          title={`${!row.is_active ? "Inactive" : "Active"}`}
          placement="top-start"
          style={{ cursor: "default" }}
        >
          <Switch
            size="small"
            className="none-events"
            checked={row.is_active}
            color="primary"
          />
        </Tooltip>
      </>
    );
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  useEffect(() => {
    if (data?.kam_id && !!appDataList.kamUsers.length) {
      const KAM = appDataList.kamUsers?.filter(
        (kam) => kam?.id === data?.kam_id
      );

      setState((prevState) => ({
        ...prevState,
        selectedKAM: KAM,
      }));
      fetchEntries(
        state.search,
        state.filters,
        state.searchColumns,
        state.pageFilters
      );
    }
  }, [data, appDataList.kamUsers]);

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.searchColumns,
      state.pageFilters
    );
  }, [fetchEntries, state.search, state.filters, state.pageFilters]);

  useEffect(() => {
    if (state.search) {
      fetchEntries(
        state.search,
        state.filters,
        state.searchColumns,
        state.pageFilters
      );
    }
  }, [state.searchColumns]);

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCautionFlagTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    fetchLeaveTypeList();
    fetchVehicleTypeList();
    fetchCautionFlagTypeList();
    fetchList("customers");
    // fetchList("IC");
    fetchList("icList");
    fetchLocalResources();
    fetchPDXCompanyList();
    fetchCustomerLocation();
    fetchList("users");
  }, [match.params.path]);

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      isHidden: !state.dynamicColumns?.roster_type?.isSelected,
      id: "roster_type",
      label: "Roster Type",
      field: "roster_type",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={!row.is_weekday ? "Weekend" : "Weekday"}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!row.is_weekday ? "Weekend" : "Weekday"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.kam?.isSelected,
      id: "kamUser.first_name",
      label: "KAM",
      field: "kamUser.first_name",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const kamName = `${row.kamUser.first_name || ""} ${
          row.kamUser.last_name || ""
        }`;
        const tooltip = `${row.kamUser.first_name || ""} ${
          row.kamUser.last_name || ""
        }-(${row.kamUser.username || ""})`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "customer_name",
      label: "Customer Name",
      field: "customer_name",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={row.customer_branch?.customer?.name || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.customer_branch?.customer?.name || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "customer_branch.location",
      label: "Location",
      field: "customer_branch.location",
      canSort: true,
      headerClassName: classes.tableHeaderNotesWidth,
      render: (row, rowIndex) => {
        // const title = `State: ${
        //   row.customer_branch?.state?.name || "-"
        // }, PDX Company: ${row.customer_branch?.pdxCompany?.value || "-"}`;
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography noWrap variant="body2">
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "routeDetails.name",
      label: "Route",
      field: "routeDetails.name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? "-"} placement="top-start">
            <Typography noWrap variant="body2">
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "customer_branch.state.name",
      label: "State",
      field: "customer_branch.state.name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.state?.name ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "customer_branch.pdxCompany.value",
      label: "PDX Company",
      field: "customer_branch.pdxCompany.value",
      headerClassName: classes.pdxCompanyHeader,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicle_type",
      label: "Vehicle",
      field: "vehicle_type",
      hasEllipses: true,
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row, rowIndex) => {
        const title = `${row.vehicleType?.value || "-"}`;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography noWrap variant="body2">
              {row.vehicleType?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "individual_contractor.first_name",
      label: "ICID/IC Name",
      field: "individual_contractor.first_name",
      canSort: true,
      render: (row) => {
        const handleLabelClick = () => {
          if (!!row.individual_contractor?.icid) {
            fetchList(
              "IC",
              `?filter[limit]=1&filter[where][icid]=${row.individual_contractor?.icid}`
            );
            setState((prevState) => ({
              ...prevState,
              showIcDetails: true,
            }));
          }
        };
        const title = !!row.individual_contractor?.icid
          ? `
          IC Settlement Company Name: ${
            row.individual_contractor?.settlementCompany?.value || "-"
          }, 
        
          ICID: ${row.individual_contractor?.icid || "-"},
          IC Name: ${row.individual_contractor?.last_name || ""} ${
              row.individual_contractor?.first_name || ""
            },
        Contractor Type: ${
          row.individual_contractor?.contractorType?.value || "-"
        }
        ${row.ic_inactive ? ", Status: Inactive ICID" : ""}
        `
          : "";
        const label = row.individual_contractor?.icid
          ? `${row.individual_contractor?.icid || ""}/${
              row.individual_contractor?.last_name || ""
            } ${row.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );

        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;
        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    noWrap
                    variant="body2"
                    style={{
                      textDecoration:
                        !!row.individual_contractor?.icid && "underline",
                      cursor: !!row.individual_contractor?.icid && "pointer",
                    }}
                    onClick={
                      !!row.individual_contractor?.icid && handleLabelClick
                    }
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      headerClassName: classes.tableHeaderwidth,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      field: "pay_rate_type",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "pay_rate",
      label: "Pay Rate",
      field: "pay_rate",
      startAdornment: "$",
      headerClassName: classes.tableHeaderwidth,
      hasEllipses: true,
      canSort: true,
    },

    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "pay_quantity",
      label: "Pay Quantity",
      field: "pay_quantity",
      headerClassName: classes.tableHeaderwidth,
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "pay_toll_amount",
      label: "Pay Toll Amount",
      field: "pay_toll_amount",
      startAdornment: "$",
      headerClassName: classes.tableHeaderwidth,
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.notes?.isSelected,
      id: "notes",
      label: "KAM Notes",
      field: "notes",
      hasEllipses: true,
      canSort: true,
      headerClassName: classes.tableHeaderNotesWidth,
      render: (row) => {
        return (
          <Tooltip
            title={row.notes ?? ""}
            placement="top-start"
            //classes={{ tooltip: classes.customSpace }}
          >
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {row.notes ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
    };
  }, []);

  const renderGridActionTab = (isBottomNav = false) => {
    return (
      <div
        className={clsx("w-100 d-flex f-align-center", {
          "f-justify-between": !isBottomNav,
          "f-justify-center": isBottomNav,
        })}
      >
        <div>
          {!isRoleVIEW && (
            <Button
              startIcon={<PlaylistAddIcon />}
              className="mr-5"
              variant="outlined"
              disabled={
                !!state.selectedKAM?.length &&
                state.selectedKAM.every((kam) => !kam.is_active)
              }
              color="primary"
              onClick={() => {
                getPrizeMatrix();
                setState((prevState) => ({ ...prevState, isNew: true }));
              }}
            >
              New
            </Button>
          )}

          <FormControlLabel
            label="Active"
            control={
              <Checkbox
                color="primary"
                checked={state.entry.status?.includes(RECORD_STATUS.ACTIVE)}
                onChange={(evt) => {
                  const updatedStatus = evt.target.checked
                    ? [...state.entry.status, RECORD_STATUS.ACTIVE]
                    : state.entry.status?.filter(
                        (item) => item !== RECORD_STATUS.ACTIVE
                      );

                  setState((prevState) => ({
                    ...prevState,

                    entry: {
                      ...prevState.entry,
                      status: updatedStatus,
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                    status: {
                      active: updatedStatus?.includes(RECORD_STATUS.ACTIVE),
                      inActive: state.entry.status?.includes(
                        RECORD_STATUS.INACTIVE
                      ),
                    },
                  });
                }}
              />
            }
          />

          <FormControlLabel
            label="Inactive"
            control={
              <Checkbox
                color="primary"
                checked={state.entry.status?.includes(RECORD_STATUS.INACTIVE)}
                // value={RECORD_STATUS.}
                onChange={(evt) => {
                  const updatedStatus = evt.target.checked
                    ? [...state.entry.status, RECORD_STATUS.INACTIVE]
                    : state.entry.status?.filter(
                        (item) => item !== RECORD_STATUS.INACTIVE
                      );

                  setState((prevState) => ({
                    ...prevState,
                    entry: {
                      ...prevState.entry,
                      status: updatedStatus,
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                    status: {
                      active: state.entry.status?.includes(
                        RECORD_STATUS.ACTIVE
                      ),
                      inActive: updatedStatus?.includes(RECORD_STATUS.INACTIVE),
                    },
                  });
                }}
              />
            }
          />
          {!!state.selectedRows.length && (
            <Button
              startIcon={<DeleteForeverOutlinedIcon color="error" />}
              classes={{
                root: "border-error ml-4",
                label: "color-error",
              }}
              variant="outlined"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  deletingEntryIds: state.selectedRows.join(","),
                }))
              }
            >
              Delete
            </Button>
          )}
        </div>
        <div className="d-flex f-align-center">
          <Dropdown
            classes={{
              paper: clsx("mr-1", classes.tunePaper),
            }}
            disablePortal={false}
            labelEllipses
            hasEllipses
            placement={isBottomNav ? "top-end" : "bottom-end"}
            options={[
              { label: "All", value: null },
              { label: "Weekdays", value: true },
              { label: "Weekend", value: false },
            ]}
            label={`Roster Type: ${
              state.filters.roasterType.length
                ? state.filters.roasterType[0]?.value
                  ? "Weekdays"
                  : "Weekend"
                : "All"
            }`}
            onChange={(option) => {
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  roasterType:
                    option.value === null
                      ? []
                      : [
                          {
                            field: "is_weekday",
                            type: "=",
                            value: option.value,
                          },
                        ],
                },
                pageNumber: defaultState.pageNumber,
              }));
              setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                roasterType:
                  option.value === null
                    ? []
                    : [
                        {
                          field: "is_weekday",
                          type: "=",
                          value: option.value,
                        },
                      ],
              });
            }}
          />
          <Dropdown
            classes={{
              paper: classes.tunePaper,
            }}
            disablePortal={false}
            remainOpen
            isMultiSelect
            placement={isBottomNav ? "top-end" : "bottom-end"}
            options={Object.keys(state.dynamicColumns).map(
              (key) => state.dynamicColumns[key]
            )}
            customToggle={({ anchorRef, onClick }) => (
              <Tooltip title="Show/Hide column(s)" placement="top-start">
                <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            )}
            onChange={(options) => {
              const isSelectAll = options.some(
                (item) => item.value === "select_all"
              );
              const isCurrentSelectAll =
                state.dynamicColumns?.select_all?.isSelected;

              const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

              const isAllOptionSelected =
                Object.keys(state.dynamicColumns).length - 1 ===
                options.filter((item) => item.value !== "select_all").length;

              const updatedDynamicColumns = Object.keys(
                state.dynamicColumns
              ).reduce((acc, key) => {
                const isSelected = isClickedOnSelectAll
                  ? isSelectAll
                  : key === "select_all"
                  ? isAllOptionSelected
                  : !!options.some((item) => item.value === key);

                return {
                  ...acc,
                  [key]: {
                    ...state.dynamicColumns[key],
                    isSelected,
                  },
                };
              }, {});
              setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                dynamicColumns: updatedDynamicColumns,
              });
              setState((prevState) => ({
                ...prevState,
                dynamicColumns: {
                  ...prevState.dynamicColumns,
                  ...updatedDynamicColumns,
                },
              }));
            }}
          />
          <Tooltip placement="top-start" title="Filter">
            <IconButton
              color="primary"
              className={clsx({
                "bg-primary": !!state.pageFilters?.length,
              })}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  isFiltering: true,
                }));
              }}
            >
              <FilterListIcon
                className={clsx({
                  "color-white": !!state.pageFilters?.length,
                })}
              />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top-end" title="Reset All Filters">
            <IconButton
              // disabled={pageConfig === undefined || pageConfig === null}
              color="primary"
              onClick={() => {
                updatePageConfig("base-roster");
                window.location.reload();
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.BASE_ROSTER, {
        search,
      });
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleMarkActiveInactive = async (markActiveInactiveIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    markActiveInactiveIds = markActiveInactiveIds.map((item) => ({
      ...item,
      status: !item.status,
    }));

    const { message, error } = await Service.markActiveInactive(
      markActiveInactiveIds
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(message || "Status updated successfully.");

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markActiveInactiveIds: [],
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchEntries(
      state.search,
      state.filters,
      state.searchColumns,
      state.pageFilters
    );
  };

  return (
    <>
      <div className="mr-5">
        <div className={clsx(" mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Base Roster
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleVIEW
              ? "View/Edit Base Roster for KAM's."
              : "View Base Roster for KAM's."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between f-align-center">
          <div className="d-flex">
            {canViweMemberBaseRoster && (
              <>
                {
                  <Autocomplete
                    classes={{
                      root: classes.kamInput,
                      inputRoot: classes.kamInputRoot,
                    }}
                    style={{ width: "244px" }}
                    disableClearable
                    size="small"
                    fullWidth
                    defaultValue={data.kam_id ? state.selectedKAM : undefined}
                    value={state.selectedKAM || []}
                    multiple
                    options={kamOptions}
                    getOptionLabel={(option) =>
                      option.name ||
                      `${option.first_name || ""} ${option.last_name || ""} - ${
                        option.email || ""
                      }-(${option.username || ""})`
                    }
                    renderOption={({
                      first_name,
                      last_name,
                      email,
                      is_active,
                      username,
                    }) => (
                      <Typography
                        variant="body1"
                        className={clsx({
                          "color-text-disabled": !is_active,
                        })}
                      >{`${first_name || ""} ${
                        last_name || ""
                      }-${email}-(${username})`}</Typography>
                    )}
                    renderInput={(params) =>
                      state.isFetchingList.users ? (
                        <Skeleton variant="rect" width="100%" height="36px" />
                      ) : (
                        <TextField {...params} label="KAM" variant="outlined" />
                      )
                    }
                    onChange={(evt, value) => {
                      if (value.length === 0) {
                        setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                          kamFilter: null,
                        });
                        return setState((prevState) => ({
                          ...prevState,
                          selectedKAM: value,
                          testId: "",
                          filters: {
                            ...prevState.filters,
                            kam: [{ field: "kam_id", type: "=", value: null }],
                          },
                        }));
                      }
                      const id = value?.map((item) => item.id);

                      setState((prevState) => ({
                        ...prevState,
                        selectedKAM: value,
                        testId: "",
                        filters: {
                          ...prevState.filters,
                          kam: [{ field: "kam_id", type: "=", value: id }],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                        kamFilter: {
                          value,
                          kam: [{ field: "kam_id", type: "=", value: id }],
                        },
                      });
                    }}
                  />
                }
              </>
            )}
            {/* <Autocomplete
            classes={{
              root: classes.kamInput,
              inputRoot: classes.kamInputRoot,
            }}
            style={{ width: "244px", marginLeft: 12 }}
            size="small"
            fullWidth
            value={state.selectedLocation || []}
            multiple
            options={locationOPtions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField {...params} label="Location" variant="outlined" />
              )
            }
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(evt, value) => {
              setState((prevState) => ({
                ...prevState,
                selectedLocation: value,
                filters: {
                  ...prevState.filters,
                  locations: [
                    {
                      field: "location",
                      type: "=",
                      value: value?.map((item) => item.value),
                    },
                  ],
                },
              }));
              setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                locationFilter: {
                  value,
                  locations: [
                    {
                      field: "location",
                      type: "=",
                      value: value?.map((item) => item.value),
                    },
                  ],
                },
              });
              gridHelper && gridHelper.resetSelection();
            }}
          /> */}
          </div>
          {((canViweMemberBaseRoster && !!state.selectedKAM) ||
            !canViweMemberBaseRoster) && (
            <div className="d-flex">
              <FormControlLabel
                label="Customer"
                control={
                  <Checkbox
                    color="primary"
                    value="customer_name"
                    checked={state.customerChecked}
                    onChange={(evt) => {
                      const { checked, value } = evt.target;
                      setState((prevState) => {
                        const searchColumns = checked
                          ? [...prevState.searchColumns, value]
                          : prevState.searchColumns.filter(
                              (item) => item !== value
                            );
                        return {
                          ...prevState,
                          searchColumns,
                          customerChecked: checked,
                        };
                      });
                      setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                        customer: checked,
                        searchColumns: checked
                          ? [value]
                          : state.searchColumns.filter(
                              (item) => item !== value
                            ),
                      });
                    }}
                  />
                }
              />
              <FormControlLabel
                label="IC"
                control={
                  <Checkbox
                    color="primary"
                    checked={state.ICChecked}
                    value="contractor_first_name"
                    onChange={(evt) => {
                      const { checked, value } = evt.target;
                      setState((prevState) => {
                        const searchColumns = checked
                          ? [...prevState.searchColumns, value, "icid"]
                          : prevState.searchColumns.filter(
                              (item) => item !== value && item !== "icid"
                            );
                        return {
                          ...prevState,
                          searchColumns,
                          ICChecked: checked,
                        };
                      });
                      setPageConfig(PAGE_KEYS.BASE_ROSTER, {
                        IC: checked,
                        searchColumns: checked
                          ? [value, "icid"]
                          : state.searchColumns.filter(
                              (item) => item !== value && item !== "icid"
                            ),
                      });
                    }}
                  />
                }
              />
              <TextField
                className={
                  state.ICChecked || state.customerChecked
                    ? ""
                    : classes.searchBox
                }
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                placeholder={
                  state.ICChecked && state.customerChecked
                    ? "Customer,IC"
                    : state.customerChecked
                    ? "Customer"
                    : state.ICChecked
                    ? "IC"
                    : "Customer,Location,Route,vehicle,IC"
                }
                defaultValue={state.search}
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(evt) =>
                  handleSearch((evt.target.value || "").trim())
                }
              />
            </div>
          )}
        </div>
        <Paper
          elevation={2}
          className={clsx("p-4 mt-10 h-100 mb-5", classes.paperSpacing)}
        >
          <div ref={actionRef}></div>
          {(canViweMemberBaseRoster || !canViweMemberBaseRoster) && (
            <>
              <div
                className={clsx("d-flex p-2 pl-4 pr-4", classes.actionsWrapper)}
              >
                {state.isFetchingList.users ||
                state.isFetchingList.customers ||
                state.isFetchingList.IC ? (
                  <Skeleton variant="rect" width={90} height={35} />
                ) : (
                  renderGridActionTab()
                )}
              </div>
              <CollapsibleGrid
                wrapRef2={wrapRef2}
                tableHeadRef={tableHeadRef}
                isRowOpen={state.isRowOpen}
                handleRowOpen={handleRowOpen}
                handleScroll={handleScroll}
                hasSelection={!isRoleVIEW}
                columns={columnConfig}
                rows={state.entries
                  .filter((entry) =>
                    state.entry.status.includes(entry.is_active)
                  )
                  .map((entry, rowIndex) => ({
                    ...entry,
                    customer_name: entry?.customer_branch?.customer?.name,
                    preventSelection: !entry.kamUser.is_active,
                    classes: {
                      individual_contractor:
                        entry.ic_inactive && classes.inactiveICID,
                    },

                    className: clsx({
                      [classes.highlightDisable]: !entry.is_active,
                      [classes.notification]: state.testId === entry?.id,
                    }),
                    rowIndex,
                  }))}
                isLoading={state.isFetching}
                uniqueBy="customer_name"
                search={state.search}
                onReady={(instance) => (gridHelper = instance)}
                onSelectionChange={handleSelectionChange}
                rowEvents={[
                  {
                    type: "onDoubleClick",
                    handler: (row) => {
                      getPrizeMatrix();
                      if (!isRoleVIEW) {
                        getPrizeMatrix();
                        setState((prevState) => {
                          return {
                            ...prevState,
                            rowBeingEdited: row.kamUser.is_active
                              ? row
                              : prevState.rowBeingEdited,
                          };
                        });
                      } else {
                        return false;
                      }
                    },
                  },
                ]}
                classes={{
                  gridActions: "f-justify-end",
                }}
              />
            </>
          )}
        </Paper>
        {appData.isTabletView && (
          <div className={clsx({ "d-none": isActionBarOnScreen })}>
            <BottomNavigation
              component={Paper}
              elevation={4}
              className={classes.bottomNavigation}
            >
              <BottomNavigationAction
                component={() => renderGridActionTab(true)}
              />
            </BottomNavigation>
          </div>
        )}

        {state.deletingEntryIds && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.paperSize,
            }}
            open={!!state.deletingEntryIds}
            contentText="Are you sure you want to delete?"
            onConfirm={() => handleDelete(state.deletingEntryIds)}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {!!state.markActiveInactiveIds.length && (
          <ActionDialog
            classes={{
              confirm: state.markActiveInactiveIds[0]?.status && "bg-primary",
            }}
            open={!!state.markActiveInactiveIds.length}
            contentText={`Are you sure you want to mark the entry ${
              state.markActiveInactiveIds[0]?.status ? "inactive" : "active"
            }?`}
            onConfirm={() =>
              handleMarkActiveInactive(state.markActiveInactiveIds)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: [],
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Mark{" "}
                {state.markActiveInactiveIds[0]?.status ? "inactive" : "active"}
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {(state.isNew || state.rowBeingEdited) && (
          <Form
            kamList={appDataList.kamUsers.filter(
              (kamUser) => kamUser.is_active === true
            )}
            isRoleKAM={isRoleKAM}
            vehicleTypeList={state.vehicleTypeList}
            selectedKAMId={kamId}
            isPriceMatrixFetched={state.isPriceMatrixFetched}
            priceMatrix={state.priceMatrix}
            selectedKam={state.selectedKAM}
            open={state.isNew || state.rowBeingEdited}
            entry={state.rowBeingEdited}
            formAlertFields={state.formAlertFields}
            isNew={state.isNew}
            currentUser={currentUser}
            kam={state.selectedKAM}
            customerList={appDataList.customers.filter(
              (customer) => !customer.is_deleted
            )}
            contractorList={appDataList.icList}
            onClose={async (isSubmitted = false, customer = {}) => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
                isNew: false,
                formAlertFields: {},
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                await (() => {
                  fetchEntries(
                    state.search,
                    state.filters,
                    state.searchColumns,
                    state.pageFilters
                  );
                  fetchList();
                })();
                gridHelper && gridHelper.toggleAll(false);
                gridHelper && gridHelper.toggle(true, customer?.name);
              }
            }}
            showFieldAlert={showFieldAlert}
          />
        )}
        {state.showIcDetails && (
          <IcForm
            open={!!state.showIcDetails}
            isTabletView={appData.isTabletView}
            isFetching={state.isIcFetching}
            entry={appDataList.IC[0]}
            usersList={appData.users}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                showIcDetails: false,
              }));
            }}
          />
        )}
        {state.fieldAlert.show && (
          <DynamicForm
            open={state.fieldAlert.show}
            title="Update Entry(s)"
            subHeading={state.fieldAlert.subHeading}
            positiveLabel="Save"
            fieldErrors={state.fieldAlert.errors}
            isLoading={state.fieldAlert.isLoading}
            configs={state.fieldAlert.configs}
            updateValidations={state.fieldAlert.updateValidations}
            onChange={state.fieldAlert.onChange}
            onSubmit={(data = {}, errors = {}) => {
              const payload = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  acc[key] =
                    key !== "bill_rate_type" &&
                    key !== "pay_rate_type" &&
                    !data[key]
                      ? "0"
                      : value;
                  return acc;
                },
                {}
              );

              handleFieldAlertSubmit(state.fieldAlert.id, payload, errors);
            }}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                fieldAlert: defaultState.fieldAlert,
              }));
            }}
          />
        )}
        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onClose={handleClose}
            kamId={kamId}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
      </div>
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={handleScroll}
          style={{
            overflowX: "auto",
            width: appData.isTabletView
              ? "calc(100vw - 42px)"
              : "calc(100vw - 110px)",
            height: "2%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              width: state.dimensions,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default ViewBaseRoster;
