import { http, responseFormatter } from "utils";

const deleteEntry = (entryIds) => {
  return responseFormatter(
    http.delete(`/route/${entryIds}`, {
      setAuthHeader: true,
    })
  );
};

const markActiveInactive = (data, type = "customer") => {
  let endpoint = "/customer-update-status";
  if (type === "location") {
    endpoint = "/customer-branch-update-status";
  }
  return responseFormatter(
    http.patch(endpoint, data, {
      setAuthHeader: true,
    })
  );
};

const postCustomer = (data) => {
  return responseFormatter(
    http.post("/customer", data, {
      setAuthHeader: true,
    })
  );
};

const postBranch = (data) => {
  return responseFormatter(
    http.post("/customer-branch", data, {
      setAuthHeader: true,
    })
  );
};

const postRoute = (data) => {
  return responseFormatter(
    http.post("/route", data, {
      setAuthHeader: true,
    })
  );
};

const putCustomer = (data) => {
  return responseFormatter(
    http.put("/customer", data, {
      setAuthHeader: true,
    })
  );
};

const putBranch = (data) => {
  return responseFormatter(
    http.put("/customer-branch", data, {
      setAuthHeader: true,
    })
  );
};

const putRoute = (data) => {
  return responseFormatter(
    http.put("/route", data, {
      setAuthHeader: true,
    })
  );
};

const deleteCustomer = (entryIds, isConfirmed = false) => {
  let queryString = `?customer_id=${entryIds}&isConfirmed=${isConfirmed}`;
  return responseFormatter(
    http.delete(`/customer${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const getAllLocations = (query) => {
  const queryString = query ? query.replace("?", "&") : "";
  return responseFormatter(
    http.get(`/addresses/get?entityType=all${queryString || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getAllNearByKamLocations = (query) => {
  const queryString = query ? query.replace("?", "&") : "";
  return responseFormatter(
    http.get(`/addresses/get?entityType=kamUser${queryString || ""}`, {
      setAuthHeader: true,
    })
  );
};

const createLocation = (data) => {
  return responseFormatter(
    http.post("/addresses/create", data, {
      setAuthHeader: true,
    })
  );
};

const getByRadius = (query) => {
  // const queryString = query ? query.replace("?", "&") : "";
  return responseFormatter(
    http.get(`/addresses/location/radius/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const updateLocation = (data) => {
  return responseFormatter(
    http.put("/addresses/update", data, {
      setAuthHeader: true,
    })
  );
};

const stateCityDropdown = () => {
  return responseFormatter(
    http.get("/addresses/dropdown", {
      setAuthHeader: true,
    })
  );
};

const kamStateCityDropdown = () => {
  return responseFormatter(
    http.get("/addresses/dropdown?entity_type=kamUser", {
      setAuthHeader: true,
    })
  );
};

const deleteLocation = (entity_type, entity_id, address_id) => {
  return responseFormatter(
    http.delete(`/addresses/delete/${entity_type}/${entity_id}/${address_id}`, {
      setAuthHeader: true,
    })
  );
};

const getLocation = (id) => {
  return responseFormatter(
    http.get(`/addresses/get/${id}`, {
      setAuthHeader: true,
    })
  );
};

const getAllKAMLocations = (query) => {
  const queryString = query ? query.replace("?", "&") : "";
  return responseFormatter(
    http.get(`/addresses/get?entityType=kam${queryString || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const deleteBranch = (entryIds, isConfirmed = false) => {
  return responseFormatter(
    http.delete(`/customer-branch/${entryIds}?isConfirmed=${isConfirmed}`, {
      setAuthHeader: true,
    })
  );
};

const deleteRoute = (entryIds, isConfirmed = false) => {
  return responseFormatter(
    http.delete(`/route/${entryIds}?isConfirmed=${isConfirmed}`, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/customers/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const priceMatrix = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getPriceMatrixNames = (customerBranchId) => {
  return responseFormatter(
    http.get(`/price-matrix/name/${customerBranchId}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerCredits = (query) => {
  return responseFormatter(
    http.get(`/invoice/customer-branch-credits${query}`, {
      setAuthHeader: true,
    })
  );
};
const patchCustomerCredits = (data) => {
  return responseFormatter(
    http.patch(`/customer-branch/available-credit`, data, {
      setAuthHeader: true,
    })
  );
};

const CustomersService = {
  delete: deleteEntry,
  priceMatrix,
  markActiveInactive,
  getCustomerLocation,
  postCustomer,
  download,
  postBranch,
  postRoute,
  updateLocation,
  deleteLocation,
  getLocation,
  putCustomer,
  putBranch,
  stateCityDropdown,
  putRoute,
  deleteCustomer,
  getLocalResources,
  deleteBranch,
  deleteRoute,
  getAllLocations,
  kamStateCityDropdown,
  createLocation,
  getAllKAMLocations,
  getByRadius,
  getAllNearByKamLocations,
  getPriceMatrixNames,
  getCustomerCredits,
  patchCustomerCredits,
};

export default CustomersService;
