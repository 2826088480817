import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/billings-and-settlements/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getFuelSurcharge = (query) => {
  return responseFormatter(
    http.get(`/calculate-fuel-surcharge/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getDCN = (query) => {
  return responseFormatter(
    http.get(`get-ic-settlement-data/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getSetting = (userId) => {
  return responseFormatter(
    http.get(`user/${userId}/settings`, {
      setAuthHeader: true,
    })
  );
};

const bulkApproveStatus = (id) => {
  return responseFormatter(
    http.get(`/billings-and-settlements/approve-all/${id}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/billings-and-settlements", data, {
      setAuthHeader: true,
    })
  );
};
const verify = (data, query = "") => {
  return responseFormatter(
    http.patch(`/approve-billings-and-settlements${query}`, data, {
      setAuthHeader: true,
    })
  );
};

const verifyAll = (data, query = "") => {
  return responseFormatter(
    http.patch(`/billings-and-settlements/approve-all${query}`, data, {
      setAuthHeader: true,
    })
  );
};

const deleteEntry = (entryId) => {
  return responseFormatter(
    http.delete(`/billings-and-settlements?recordIds=${entryId}`, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/billings-and-settlements", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data, id) => {
  return responseFormatter(
    http.patch(
      "/billings-and-settlements",
      { ...data, id },
      {
        setAuthHeader: true,
      }
    )
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/billingAndSettlements/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const sendToDCN = (data) => {
  return responseFormatter(
    http.post("/upload-to-dcn", data, {
      setAuthHeader: true,
    })
  );
};
const addNotesInLookup = (payload) => {
  return responseFormatter(
    http.post(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};
const priceMatrix = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const revertDsSubmit = (data) => {
  return responseFormatter(
    http.patch("/billings-and-settlements/revert-DS-submit ", data, {
      setAuthHeader: true,
    })
  );
};

const getPrevUnaaprovedRecord = (query) => {
  return responseFormatter(
    http.get(`/billings-and-settlement/prev-unapproved-records${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const DailyScheduleService = {
  get,
  getDCN,
  priceMatrix,
  post,
  delete: deleteEntry,
  getCustomerLocation,
  put,
  verify,
  patch,
  download,
  getLocalResources,
  sendToDCN,
  getFuelSurcharge,
  getSetting,
  addNotesInLookup,
  verifyAll,
  bulkApproveStatus,
  revertDsSubmit,
  getPrevUnaaprovedRecord,
};

export default DailyScheduleService;
