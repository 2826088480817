import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  holdPaddingLeft: {
    paddingLeft: "21px !important",
  },
  icidFlagTitle: {
    paddingLeft: "48px !important",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  paperHeight: {
    minHeight: 320,
  },
  paperSize: {
    width: 388,
  },
  addLookupEmailpaperSize: {
    width: 530,
  },
  total: {
    backgroundColor: "#D3D3D3",
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "16px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  senToDcnPaperSize: {
    width: 600,
    "& .MuiDialogContent-root": {
      paddingTop: "0px",
    },
    height: 527,
    overflowY: "auto",
  },
  notesPaperSize: {
    width: 388,
    height: 244,
  },
  tempExcludePaperSize: {
    width: 388,
  },
  isIncludingRecords: {
    width: 388,
  },
  permExcludePaperSize: {
    width: 388,
    height: 244,
  },
  paper_Size: {
    width: 488,
  },
  paper_Sizes: {
    width: 498,
  },
  paperSpacing: {
    marginTop: "0px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  tunePaper: {
    minWidth: 232,
  },
  highlightUpdate: {
    background: `${yellow[50]} !important`,
  },
  highlightApproved: {
    background: blue[100],
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]} !important`,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  customSpace: {
    whiteSpace: "pre",
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  settlementCompanyDropdown: {
    marginTop: "14px",
    minWidth: 198,
    maxWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  dcnPopUpHeader: {
    color: "black",
    background: "rgb(255, 244, 229)",
    width: "65%",
    zIndex: 1000,
    position: "fixed",
    paddingBottom: "4px",
    paddingTop: "4px",
    marginTop: "0px !important",
  },
  payDateGap: { gap: "6px" },
  payDateSize: { fontSize: "1rem" },
  noEmail: {
    marginTop: "4px",
    marginBottom: "22px",
    color: "#a99f41",
  },
  emailChip: {
    marginRight: "4px",
    marginBottom: "4px",
    marginTop: "6px",
  },
  VisibilityIconColor: { color: "#dd4e54" },
  disabledIconColor: { color: "#cdcdcd" },
  filterFields: {
    marginLeft: 12,
    minWidth: 168,
    maxWidth: 168,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  icidTitle: {
    minWidth: "450px",
  },
  // icidFlagTitle: {
  //   minWidth: "450px",
  //   paddingLeft: "48px",
  // },
  icidTitleColor: {
    background: `${yellow[50]}`,
  },
  extraMilesheader: {
    minWidth: 148,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
  tabs: {
    background: "#eeeeee",
    maxWidth: "22.7rem",
    boxShadow:
      "0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginTop: "1.5rem",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  permExcludedRecords: {
    background: "#ececec",
  },
  buttonGroup: { marginTop: "1rem" },
  button: {
    border: "none",
    borderBottom: "2px solid rgb(250 250 250)",
    borderRadius: 0,
  },
  selectedButton: {
    borderBottom: "2px solid #004700",
    color: "#004700",
  },
}));

export default useStyles;
