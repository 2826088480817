import { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Datepicker, Dialog } from "shared/components";
import { getDateString, getPageConfig, setPageConfig } from "utils";
import { AppContext } from "shared/contexts";
import SharedService from "modules/shared/service";
import { Box, Typography } from "@material-ui/core";
import Service from "../service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";
import { PAGE_KEYS } from "shared";
import { ButtonGroup, Paper } from "@material-ui/core";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BarChartIcon from "@material-ui/icons/BarChart";
import StackedBarChart from "./BarChartReport";
import MyBarChart from "./BarChartReport/barChartDialog";

const defaultState = {
  isFetching: false,
  sortRecordOrder: "desc",
  sortReviewOrder: "desc",
  entries: [],
  totalEntries: 0,
  isBarClicked: false,
  entry: {},
  startDate: null,
  endDate: null,
  filters: {
    kamId: [],
  },
  isFetchingList: {
    kams: false,
  },
};

const AaMgrReport = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.AA_MGR_BS_SUBMIT_REPORT);
  const [state, setState] = useState({
    ...defaultState,
    startDate:
      (pageConfig && pageConfig?.startDateFilter) ||
      (() => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);
        return getDateString(startDate);
      })(),
    endDate:
      (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),

    sortRecordOrder:
      (pageConfig && pageConfig?.sortRecordOrder) ||
      defaultState.sortRecordOrder,
    sortReviewOrder:
      (pageConfig && pageConfig?.sortReviewOrder) ||
      defaultState.sortReviewOrder,
    filters: {
      ...defaultState.filters,
      kamId:
        (pageConfig && pageConfig?.kamFilter?.value) ||
        defaultState.filters.kamId,
    },
  });

  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });

  const fetchEntries = useCallback(
    async (startDate, endDate, filters, hasLoader = true) => {
      let queryString =
        filters.kamId?.length > 0
          ? `?fromDate=${startDate}&toDate=${endDate}&kamIds=${filters.kamId
              ?.map((item) => item.id)
              ?.join(",")}`
          : `?fromDate=${startDate}&toDate=${endDate}`;

      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const { data, error } = await Service.getAaMgrBsReport(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      const groupedData = data.rows.reduce((acc, item) => {
        const { kam_id, kamUser } = item;

        if (!acc[kam_id]) {
          acc[kam_id] = {
            kam_id,
            kamUser,
            totalRecords: 0,
            detailedKamReport: [],
          };
        }

        acc[kam_id].totalRecords += 1;

        acc[kam_id].detailedKamReport.push(item);

        return acc;
      }, {});

      const result = Object.values(groupedData);

      const kamData =
        result.length > 0
          ? result.map((entry) => {
              const matchingUser = entry.kamUser;

              const kamName =
                matchingUser &&
                `${matchingUser.first_name} ${matchingUser.last_name}`;

              return {
                ...entry,
                kamName: kamName,
              };
            })
          : [];

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: kamData || defaultState.entries,
        totalEntries: data.count,
      }));
    },
    []
  );

  const fetchUsersList = async (...params) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          kams: true,
        },
      }));

      const serviceMethod = "getUsersList";
      const responseKey = "users";

      const { data, error } = await SharedService[serviceMethod](...params);

      if (error) {
        throw error;
      }

      const listData = data[responseKey] || [];

      updateContextData("users", listData);

      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          kams: false,
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,

        isFetchingList: {
          ...prevState.isFetchingList,
          kams: false,
        },
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    }
  };

  const handleClick = (entry = {}) => {
    setState((prevState) => ({
      ...prevState,
      isBarClicked: true,
      entry,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      isBarClicked: false,
    }));
  };

  const sortData = (data, sortOrder, orderValue) => {
    const sortedData = [...data];

    if (sortOrder === "asc" || sortOrder === "desc") {
      sortedData.sort((a, b) => {
        let valueA = a[orderValue];
        let valueB = b[orderValue];

        return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      });
    }

    return sortedData;
  };

  const renderDialog = (entry) => {
    const getTotalReviewCount = (data) => {
      return data.reduce((total, item) => total + item.review_count, 0);
    };

    const aggregatedData = {};

    entry.detailedKamReport.forEach((report) => {
      const key =
        report.aa_id !== null ? `AA_${report.aa_id}` : `MGR_${report.mgr_id}`;

      const aaUser =
        appData?.users?.length > 0 &&
        appData.users.find(
          (ele) => (ele?.role === "AA" && ele?.id === report?.aa_id) || null
        );
      const mgrUser =
        appData?.users?.length > 0 &&
        appData.users.find(
          (ele) => (ele?.role === "MGR" && ele?.id === report?.mgr_id) || null
        );

      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          reviewCount: report.review_count,
          recordCount: 1,
          role: report.aa_id === null ? mgrUser?.role : aaUser?.role,
          name:
            report.aa_id === null
              ? `${mgrUser?.first_name} ${mgrUser?.last_name}`
              : `${aaUser?.first_name} ${aaUser?.last_name}`,
          tooltipName:
            report.aa_id === null
              ? `${mgrUser?.role}: ${mgrUser?.first_name} ${mgrUser?.last_name}`
              : `${aaUser?.role}: ${aaUser?.first_name} ${aaUser?.last_name}`,
        };
      } else {
        aggregatedData[key].reviewCount += report.review_count;
        aggregatedData[key].recordCount += 1;
      }
    });

    const barChartData = Object.values(aggregatedData);
    return (
      <Dialog
        open={!!state.isBarClicked}
        onClose={handleCloseDialog}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>
          <div className="mb-2">
            <Typography variant="h4" color="primary" className="ml-2">
              AA/MGR Review Summary
            </Typography>
            <Typography
              variant="body2"
              style={{ marginLeft: "10px", color: "#000" }}
            >
              {`AA/MGR Reviews Overview for Billing and Settlements Records of `}
              <span
                style={{ color: "#004700" }}
              >{`KAM: ${entry.kamName}`}</span>
            </Typography>
          </div>
        </Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center f-justify-between f-wrap">
            <Chip
              label={`Total Review Count: ${getTotalReviewCount(
                entry.detailedKamReport
              )}`}
              className={classes.directSchedule}
            />
            <div className="d-flex f-align-center f-wrap f-justify-end m-2">
              <div className="d-flex f-align-center f-justify-start">
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Tooltip
                    title="Sort in Ascending Order"
                    placement="top-start"
                  >
                    <Button
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          sortReviewOrder: "asc",
                        }));
                      }}
                      disabled={state.sortReviewOrder === "asc"}
                    >
                      <TrendingUpIcon
                        color={
                          state.sortReviewOrder === "asc" ? "grey" : "primary"
                        }
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Sort in Descending Order"
                    placement="top-start"
                  >
                    <Button
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          sortReviewOrder: "desc",
                        }));
                      }}
                      disabled={state.sortReviewOrder === "desc"}
                    >
                      <TrendingDownIcon
                        color={
                          state.sortReviewOrder === "desc" ? "grey" : "primary"
                        }
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Remove Sorting" placement="top-start">
                    <Button
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          sortReviewOrder: "none",
                        }));
                      }}
                      disabled={state.sortReviewOrder === "none"}
                    >
                      <BarChartIcon
                        color={
                          state.sortReviewOrder === "none" ? "grey" : "primary"
                        }
                      />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </div>
            </div>
          </div>

          <MyBarChart
            entries={sortData(
              barChartData,
              state.sortReviewOrder,
              "reviewCount"
            )}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-end p-4">
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    );
  };

  useEffect(() => {
    fetchEntries(state.startDate, state.endDate, state.filters);
  }, [fetchEntries, state.startDate, state.endDate, state.filters]);

  useEffect(() => {
    fetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={clsx("mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary" className=" ml-2">
          AA/MGR BS Submit Reports
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/report")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Reports
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          View AA/MGR BS Submit Reports.
        </Typography>
      </div>
      <div className="d-flex f-align-center f-wrap mb-3 mt-4 f-justify-between">
        <div
          className="d-flex f-align-center"
          style={{ paddingBottom: "10px" }}
        >
          <Datepicker
            mask
            preventClear
            label="Start date"
            selected={new Date(state.startDate)}
            maxDate={new Date(state.endDate)}
            classes={{
              input: {
                root: clsx("mr-4 mt-4", classes.datepickerWrapper),
              },
            }}
            onChange={(startDate) => {
              setState((prevState) => ({
                ...prevState,
                startDate: getDateString(startDate),
              }));
              setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                startDateFilter: getDateString(startDate),
              });
            }}
          />
          <Datepicker
            mask
            preventClear
            label="End date"
            selected={new Date(state.endDate)}
            minDate={new Date(state.startDate)}
            maxDate={new Date()}
            classes={{
              input: {
                root: clsx("mr-4 mt-4", classes.datepickerWrapper),
              },
            }}
            onChange={(endDate) => {
              setState((prevState) => ({
                ...prevState,
                endDate: getDateString(endDate),
              }));
              setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                endDateFilter: getDateString(endDate),
              });
            }}
          />

          <Autocomplete
            className={clsx("mr-4 mt-4", classes.kamDropdown)}
            classes={{
              paper: "mb-2",
            }}
            size="small"
            ChipProps={{
              component: (a) => {
                return (
                  <div>
                    <Tooltip
                      placement={"top-start"}
                      title={a.children[1]?.props?.children || ""}
                    >
                      <Chip
                        size="small"
                        className={classes.chip}
                        width={15}
                        label={
                          <Typography variant="body3" noWrap>
                            {a.children[1]?.props?.children.length > 4
                              ? a.children[1]?.props?.children.slice(0, 4) +
                                "..."
                              : a.children[1]?.props?.children}
                          </Typography>
                        }
                        deleteIcon
                        onDelete={(evt) => {
                          a.children[2]?.props?.onClick(evt);
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              },
            }}
            defaultValue={state.filters?.kamId}
            options={appData.users.filter(
              (user) => user.role === "KAM" && user.is_active
            )}
            multiple
            getOptionLabel={(option) =>
              option.name ||
              `${option.first_name || ""} ${option.last_name || ""}-${
                option.email || ""
              }-(${option.username || ""})`
            }
            renderInput={(params) =>
              state.isFetchingList.kams ? (
                <Skeleton variant="rect" width="100%" height="42px" />
              ) : (
                <TextField {...params} label="KAM" variant="outlined" />
              )
            }
            onChange={(evt, kam) => {
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  kamId: kam,
                },
              }));
              // setPageConfig(PAGE_KEYS.AA_MGR_BS_SUBMIT_REPORT, {
              //   kamFilter: {
              //     value: kam,
              //   },
              // });
            }}
          />
        </div>
        <Paper
          style={{
            borderRadius: "10px",
            marginTop: appData.isTabletView ? "10px" : "0px",
          }}
        >
          <div className="d-flex f-align-center f-wrap f-justify-end m-2">
            <div className="d-flex f-align-center f-justify-start">
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Tooltip title="Sort in Ascending Order" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortRecordOrder: "asc",
                      }));
                      // setPageConfig(PAGE_KEYS.AA_MGR_BS_SUBMIT_REPORT, {
                      //   sortOrder: "asc",
                      // });
                    }}
                    disabled={state.sortRecordOrder === "asc"}
                  >
                    <TrendingUpIcon
                      color={
                        state.sortRecordOrder === "asc" ? "grey" : "primary"
                      }
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Sort in Descending Order" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortRecordOrder: "desc",
                      }));
                      // setPageConfig(PAGE_KEYS.AA_MGR_BS_SUBMIT_REPORT, {
                      //   sortOrder: "desc",
                      // });
                    }}
                    disabled={state.sortRecordOrder === "desc"}
                  >
                    <TrendingDownIcon
                      color={
                        state.sortRecordOrder === "desc" ? "grey" : "primary"
                      }
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Remove Sorting" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortRecordOrder: "none",
                      }));
                      // setPageConfig(PAGE_KEYS.AA_MGR_BS_SUBMIT_REPORT, {
                      //   sortOrder: "none",
                      // });
                    }}
                    disabled={state.sortRecordOrder === "none"}
                  >
                    <BarChartIcon
                      color={
                        state.sortRecordOrder === "none" ? "grey" : "primary"
                      }
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>
            {/* <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                color="primary"
                className="mb-1"
                onClick={() => {
                  updatePageConfig("kam-submission-report");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        </Paper>
      </div>
      <Box>
        <Paper
          className={classes.paperSpacing}
          style={{
            ...(appData.isTabletView
              ? { overflow: "auto" }
              : { width: "100%" }),
          }}
        >
          <StackedBarChart
            entries={sortData(
              state.entries,
              state.sortRecordOrder,
              "totalRecords"
            )}
            handleClick={handleClick}
            isFetching={state.isFetching}
            isTabletView={appData.isTabletView}
          />
          {state.isBarClicked && renderDialog(state.entry)}
        </Paper>
      </Box>
    </>
  );
};

export default AaMgrReport;
